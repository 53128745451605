import React, { useState, useEffect } from 'react';
import SelectUser from './SelectUsuarios';
import URLFireBase from '../config/apiConfig';
import Styles from './Styles/UserTable.module.css';
import { AddButton, EditButton, CloseButton } from './IconsButtons';
import CrearUsuarioModal from '../modales/CrearUsuarioModal';
import EditarUsuarioModal from '../modales/EditarUsuarioModal';
import ConfirmDeleteModal from '../modales/ConfirmDeleteModal'; 
import { Alert, Stack } from '@mui/material';
import { auth } from '../Firebase/firebaseConfig'; 

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [userToDelete, setUserToDelete] = useState(null); 

  const fetchUsers = async (role) => {
    setLoading(true);
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('Usuario no autenticado.'); 
      }
      const token = await currentUser.getIdToken();

      const endpoint = role ? `${URLFireBase}role/${role}` : `${URLFireBase}users`;
      const response = await fetch(endpoint, {
        method: 'GET',
         headers: {
            Authorization: `Bearer ${token}`, 
          },
      });
      if (!response.ok) {
        throw new Error('Error al obtener los datos');
      }
      const data = await response.json();
      const sortedUsers = data.sort((a, b) => {
        if (a.role === 'profesor' && b.role !== 'profesor') return -1;
        if (a.role !== 'profesor' && b.role === 'profesor') return 1;
        return 0;
      });
      setUsers(sortedUsers);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true); 
  };

  const handleDelete = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true); 
  };

  const confirmDelete = async () => {
    if (!userToDelete) return;
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('Usuario no autenticado.'); 
      }
      const token = await currentUser.getIdToken();

      const response = await fetch(`${URLFireBase}eliminar/${userToDelete.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error('Error al eliminar el usuario');
      }
  
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));
  
      handleSuccess('Usuario eliminado exitosamente');
      setShowDeleteModal(false); 
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      handleError('No se pudo eliminar el usuario');
      setShowDeleteModal(false); 
    }
  };
  

  const handleUserUpdated = (updatedUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === updatedUser.id ? updatedUser : user
      )
    );
  };
  
  const handleEditSuccess = (updatedUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    );
    handleSuccess('Usuario editado exitosamente');
  };
  
  const handleFilterChange = (role) => {
    fetchUsers(role);
  };

  useEffect(() => {
    fetchUsers('profesor');
  }, []);

  const handleAddUser = () => {
    setShowCreateModal(true); 
  };

  const handleCloseCreateModal = () => {

    setShowCreateModal(false); 

  };

  const handleCloseEditModal = () => {
    setShowEditModal(false); 

  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false); 

  };

  const handleSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const handleError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 3000);
  };

  return (
    <div className={Styles['table-container']}>
  

      {/* Modal para crear usuario */}
      {showCreateModal && (
        <CrearUsuarioModal
          open={showCreateModal}
          onClose={handleCloseCreateModal}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      )}

      {/* Modal para editar usuario */}
      {showEditModal && selectedUser && (
       <EditarUsuarioModal
       open={showEditModal}
       onClose={handleCloseEditModal}
       onSuccess={handleSuccess}
       onError={handleError}
       userData={selectedUser}
       onUserUpdated={handleUserUpdated}
     />
     
      
      )}

      {/* Modal para confirmar eliminación */}
      {showDeleteModal && userToDelete && (
        <ConfirmDeleteModal
          open={showDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={confirmDelete}
          userName={userToDelete.firstName + ' ' + userToDelete.lastName}
        />
      )}

      <div className={Styles['search-select-container']}>
        <SelectUser onFilterChange={handleFilterChange} />
        <AddButton onClick={handleAddUser} />
      </div>

      {(success || error) && (
        <div style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          zIndex: 1000,
          width: '400px'
        }}>
          <Stack spacing={2}>
            <Alert severity={error ? "error" : "success"} onClose={() => { setError(''); setSuccess(''); }}>
              {error || success}
            </Alert>
          </Stack>
        </div>
      )}

      {/* Tabla de usuarios */}
      <div>
        <table className={Styles['user-table']}>
          <thead>
            <tr>
              <th>Nombres</th>
              <th>Apellidos</th>
              <th>Email</th>
              {users[0]?.role !== 'administrador' && <th>Matrícula</th>}
              {users[0]?.role === 'alumno' && <th>Grupo</th>}
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <tr key={user.id}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  {user.role !== 'administrador' && <td>{user.enrollment}</td>}
                  {user.role === 'alumno' && <td>{user.group}</td>}
                  <td className={Styles.actions}>
                    <div className={Styles['acciones-button']}>
                      <EditButton onClick={() => handleEdit(user)} />
                      <CloseButton onClick={() => handleDelete(user)} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No hay datos disponibles</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;