import React from 'react';
import { FaArrowLeft, FaCrop, FaSync, FaTimes, FaDownload, FaPlus, FaPencilAlt } from 'react-icons/fa';

const IconButton = ({ Icon, onClick }) => (
  <button onClick={onClick} style={styles.button}>
    <Icon size={60} color="#FFFFFF" />
  </button>
);

export const BackButton = (props) => <IconButton Icon={FaArrowLeft} {...props} />;
export const ScanButton = (props) => <IconButton Icon={FaCrop} {...props} />;
export const RefreshButton = (props) => <IconButton Icon={FaSync} {...props} />;
export const CloseButton = (props) => <IconButton Icon={FaTimes} {...props} />;
export const DownloadButton = (props) => <IconButton Icon={FaDownload} {...props} />;
export const AddButton = (props) => <IconButton Icon={FaPlus} {...props} />;
export const EditButton = (props) => <IconButton Icon={FaPencilAlt} {...props} />;

const styles = {
  button: {
    backgroundColor: '#592B87',
    borderRadius: '10px', // Adjust the border radius as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    margin: '5px',
    padding: '10px', // Add padding for better spacing
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Add a subtle shadow
    transition: 'background-color 0.3s ease-in-out',
    width: '40px', // Set a specific width
    height: '40px', // Set a specific height
  },
  buttonHover: {
    backgroundColor: '#73469B', // Darker background on hover
  }
};