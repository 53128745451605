import React, { useState, useEffect } from "react";
import { Modal, Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Alert, Stack } from "@mui/material";
import { auth } from '../Firebase/firebaseConfig'; 
import URLFireBase from "../config/apiConfig";

const EditarUsuarioModal = ({ open, onClose, onSuccess, onError, userData, onUserUpdated  }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [enrollment, setEnrollment] = useState("");
  const [group, setGroup] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setRole(userData.role);
      setEnrollment(userData.enrollment);
      setGroup(userData.group || "");
      setPassword(""); 
    }
  }, [userData]);

  const updateUser = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('Usuario no autenticado.'); 
      }
      const token = await currentUser.getIdToken();

      const response = await fetch(`${URLFireBase}actualizar/${userData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, 

        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          role,
          enrollment,
          group,
        }),
      });

      if (response.ok) {
        const updatedUser = await response.json(); 
        onSuccess("Usuario actualizado exitosamente");
        onUserUpdated(updatedUser); 
        onClose();
      } else {
        onError("Error al actualizar el usuario");
      }
    } catch (error) {
      onError("Error al actualizar el usuario: " + error.message);
    }
  };

  const handleSubmit = () => {
    updateUser();
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#ffffff",
            borderRadius: 2,
            padding: "30px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ textAlign: "center" }}>Editar Usuario</h2>
          <form style={{ width: "100%" }}>
            <FormControl fullWidth sx={{ marginBottom: "15px" }}>
              <InputLabel>Role</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                label="Role"
                variant="outlined"
                size="small"
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="profesor">Profesor</MenuItem>
                <MenuItem value="alumno">Alumno</MenuItem>
              </Select>
            </FormControl>
            {role === "alumno" && (
              <TextField
                label="Group"
                fullWidth
                sx={{ marginBottom: "15px" }}
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                variant="outlined"
                size="small"
              />
            )}
            <TextField
              label="Nombres"
              fullWidth
              sx={{ marginBottom: "15px" }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              size="small"
            />
            <TextField
              label="Apellidos"
              fullWidth
              sx={{ marginBottom: "15px" }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              size="small"
            />
            <TextField
              label="Email"
              fullWidth
              sx={{ marginBottom: "15px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              size="small"
            />
            <TextField
              label="Matrícula"
              fullWidth
              sx={{ marginBottom: "15px" }}
              value={enrollment}
              onChange={(e) => setEnrollment(e.target.value)}
              variant="outlined"
              size="small"
            />
      
            <div style={{ display: "flex", gap: "10px", justifyContent: "center", width: "100%" }}>
              <Button
                sx={{
                  borderRadius: "20px",
                  padding: "10px 20px",
                  border: "2px solid transparent",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  fontWeight: "bold",
                  borderColor: "#b0b0b0",
                  color: "#b0b0b0",
                  "&:hover": {
                    backgroundColor: "#b0b0b0",
                    color: "#ffffff",
                  },
                }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                sx={{
                  borderRadius: "20px",
                  padding: "10px 20px",
                  border: "2px solid transparent",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  fontWeight: "bold",
                  borderColor: "#742b90",
                  color: "#742b90",
                  "&:hover": {
                    backgroundColor: "#742b90",
                    color: "#ffffff",
                  },
                }}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditarUsuarioModal;
