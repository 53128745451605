import { auth } from "../Firebase/firebaseConfig"; 
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import URLFireBase from './apiConfig';

const useAuthGuard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null); 
  const [userId, setUserId] = useState(null); 

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      const user = auth.currentUser;

      if (!user) {
        navigate('/');
        return;
      }

      setUserId(user.uid);

      try {
        const token = await user.getIdToken();
        
        const response = await fetch(`${URLFireBase}profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error("No autorizado o error en la solicitud");
        }

        const responseData = await response.json();
        setData(responseData);
        setLoading(false); 
      } catch (error) {
        console.error("Error al verificar autenticación o al obtener datos:", error);
        setError("No se pudo verificar la autenticación o obtener datos.");
        setLoading(false); 
        navigate('/'); 
      }
    };

    checkAuthAndFetchData();
  }, [navigate]);

  return { loading, error, data, userId };
};

export default useAuthGuard;
