import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../Firebase/firebaseConfig";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Stack } from '@mui/material';
import styles from '../styles/Login.module.css';
import URLFireBase from '../config/apiConfig';
import { useUser } from '../pages/UserContext'; 

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();
  const { setUserName } = useUser(); 

  const handleGoogleLogin = async () => {
    try {
      await signOut(auth);
      const result = await signInWithPopup(auth, googleProvider);
      const email = result.user.email;
  
      const emailExists = await checkEmailExists(email);
  
      if (emailExists) {
        const token = await result.user.getIdToken();
        const response = await fetch(`${URLFireBase}profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const userData = await response.json();
          const name = userData.name || result.user.displayName; 
  
          setUserName(name);  
  
          const role = userData.role?.toLowerCase();
          if (role === 'admin') {
            navigate('/GestionUsuarios');
          } else if (role === 'profesor') {
            navigate('/CrearExamen');
          } else {
            setError("Rol no reconocido");
          }
        } else {
          setError("No autorizado");
        }
      } else {
        setError("El correo no está registrado en el sistema");
      }
    } catch (error) {
      setError("Error al iniciar sesión con Google");
    }
  };
  

  const handleLogin = async () => {
    try {
      await signOut(auth);
  
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
  
      const token = await userCredential.user.getIdToken();
  
      const response = await fetch(`${URLFireBase}profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const userData = await response.json();
  
        const firstName = userData.firstName || userCredential.user.displayName;  
        setUserName(firstName);
  
        if (userData.role === 'admin') {
          navigate('/GestionUsuarios');
        } else if (userData.role === 'profesor') {
          navigate('/CrearExamen');
        }
      } else {
        setError('No autorizado');
      }
    } catch (error) {
      setError('Error en el inicio de sesión');
    }
  };
  
  
  
  const checkEmailExists = async (email) => {
    try {
      const response = await fetch(`${URLFireBase}checkEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) throw new Error("Error en la verificación de correo");

      const data = await response.json();
      return data.exists;
    } catch (error) {
      setError("Error al verificar el correo: " + error.message);
      return false;
    }
  };
  const isButtonEnabled = email.trim() !== '' && password.trim() !== '';

  return (
    <div className={styles.inicioDeSesin}>
      <div className={styles.leftSection}>
        <img className={styles.imgIniciosesionIcon} alt="Workspace" src="/imagens/img-inicioSesion.jpg" />
      </div>
      <div className={styles.rightSection}>
        <div className={styles.logoWrapper}>
          <img className={styles.logoIcon} alt="Logo" src="/imagens/logo.png" />
          <div className={styles.testflow}>TESTFLOW</div>
        </div>
        <div className={styles.formWrapper}>
          <h2 className={styles.inicioDeSesin1}>Inicio de sesión</h2>
          <div className={styles.textField}>
            <label className={styles.label}>Ingresa correo</label>
            <input
              className={styles.textFieldInput}
              type="text"
              placeholder="Correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.textField}>
            <label className={styles.label}>Ingresa contraseña</label>
            <div className={styles.passwordWrapper}>
              <input
                className={styles.textFieldInput}
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          {error && (
            <div className={styles.alertContainer}>
              <Stack spacing={2}>
                <Alert severity="error" onClose={() => setError('')}>
                  {error}
                </Alert>
              </Stack>
            </div>
          )}

        <button
            className={`${styles.submitButton} ${isButtonEnabled ? styles.active : ''}`}
            onClick={handleLogin}
            disabled={!isButtonEnabled}
          >
            Ingresar
          </button>
          <button className={styles.googleButton} onClick={handleGoogleLogin}>
            <img src="/imagens/google-icon.png" alt="Google icon" className={styles.googleIcon} />
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;