import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement } from 'chart.js';
import Header from '../../components/Header';
import TabProfesor from '../../components/TabProfedor';
import useAuthGuard from '../../config/authGuard';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement);

const Resultados = () => {
  const { error, data } = useAuthGuard();
    const { state } = useLocation();
    const { calificaciones = [], examen = '', grupo = '' } = state || {};

    const [chartData, setChartData] = useState(null);

    useEffect(() => {
      if (calificaciones.length > 0) {
          const grades = calificaciones.map(item => item.calificacionNumerica);
  
          const gradeDistribution = Array(11).fill(0);
          grades.forEach(grade => {
              gradeDistribution[Math.round(grade)] += 1;
          });
  
          const filteredGrades = gradeDistribution
              .map((count, index) => ({ grade: index, count }))
              .filter(item => item.count > 0);
  
          const filteredLabels = filteredGrades.map(item => item.grade);
          const filteredData = filteredGrades.map(item => item.count);
  
          const total = grades.reduce((sum, grade) => sum + grade, 0);
          const average = total / grades.length;
          const sortedGrades = [...grades].sort((a, b) => a - b);
          const median = sortedGrades.length % 2 === 0
              ? (sortedGrades[sortedGrades.length / 2 - 1] + sortedGrades[sortedGrades.length / 2]) / 2
              : sortedGrades[Math.floor(sortedGrades.length / 2)];
          const variance = grades.reduce((sum, grade) => sum + Math.pow(grade - average, 2), 0) / grades.length;
          const standardDeviation = Math.sqrt(variance);
  
          const passFail = grades.filter(grade => grade >= 8).length; 
          const failCount = grades.length - passFail; 
  
          setChartData({
              barChartData: {
                  labels: filteredLabels,
                  datasets: [{
                      label: 'Cantidad de estudiantes por calificación',
                      data: filteredData,
                      backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  }],
              },
              pieChartData: {
                  labels: ['Aprobado', 'Reprobado'],
                  datasets: [{
                      data: [passFail, failCount],
                      backgroundColor: ['#36a2eb', '#ff6384'],
                  }],
              },
              lineChartData: {
                  labels: calificaciones.map(item => item.enrollment),
                  datasets: [{
                      label: 'Calificación por Alumno',
                      data: grades,
                      fill: false,
                      borderColor: '#742774',
                      tension: 0.1,
                  }],
              },
              statistics: {
                  average,
                  median,
                  standardDeviation,
                  passFailCount: passFail,
                  failCount,
              },
          });
      }
  }, [calificaciones]);
  
  
  

  return (
    <div>
      |<Header />
    <div style={styles.container}>
      <TabProfesor />
      <h1 style={styles.title}>Resultados de {examen}</h1>
      <div style={styles.statisticsContainer}>
      <h2>Grupo: {grupo}</h2>
      <p><strong>Promedio:</strong> {chartData ? chartData.statistics.average.toFixed(2) : 'N/A'}</p>
        <p><strong>Mediana:</strong> {chartData ? chartData.statistics.median : 'N/A'}</p>
        <p><strong>Desviación Estándar:</strong> {chartData ? chartData.statistics.standardDeviation.toFixed(2) : 'N/A'}</p>
        <p><strong>Aprobados:</strong> {chartData ? chartData.statistics.passFailCount : 'N/A'}</p>
        <p><strong>Reprobados:</strong> {chartData ? chartData.statistics.failCount : 'N/A'}</p>
      </div>

      {chartData && (
        <div style={styles.graphsContainer}>
          <div style={styles.graphContainer}>
            <h3>Distribución de Calificaciones</h3>
            <Bar data={chartData.barChartData} options={styles.chartOptions} />
          </div>

          <div style={styles.graphContainer}>
            <h3>Aprobación/Reprobación </h3>
            <Pie data={chartData.pieChartData} options={styles.chartOptions} />
          </div>

          <div style={styles.graphContainer}>
            <h3>Calificación por Alumno</h3>
            <Line data={chartData.lineChartData} options={styles.chartOptions} />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',  
  },
  title: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  statisticsContainer: {
    display: 'inline-block',  
    textAlign: 'center',  
    marginBottom: '30px',
    backgroundColor: '#f9f9f9',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: '80%',  
  },
  graphsContainer: {
    display: 'flex',
    justifyContent: 'center',  
    gap: '20px',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  graphContainer: {
    width: '100%',
    maxWidth: '500px',
    marginBottom: '20px',
  },
  chartOptions: {
    responsive: true,
    maintainAspectRatio: true,
  },
};

export default Resultados;