import React from 'react';
import Header from '../../components/Header';
import CreacionExam from '../../components/CreacionExam'
import TabProfesor from '../../components/TabProfedor';

const Examen = () => {
  return (
    <div className="profesor-dashboard">
      <Header />
      <div className="main-content">
        <TabProfesor />
        <CreacionExam/>

      </div>
    </div>
  );
};

export default Examen;
