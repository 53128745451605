import React, { useState, useEffect } from 'react';
import { jsPDF } from "jspdf";
import styles from './Styles/CreacionExam.module.css';
import URLFireBase from '../config/apiConfig';
import URLMONGO from '../config/apiMongo';
import katex from "katex";
import "katex/dist/katex.min.css";
import html2canvas from 'html2canvas';
import useAuthGuard from '../config/authGuard';
import { auth } from "../Firebase/firebaseConfig";

const CreacionExam = () => {
    const { loading, error, data, userId } = useAuthGuard(); // Accede al UID

    const [form, setForm] = useState({
        titulo: '',
        subtitulo: '',
        fecha: '',
        instrucciones: '',
        grupo: '',
        tema: '',
        numPreguntas: '',
        dificultad: '',
        examenesDiferentes: 1,
        hojaRespuestas: false,
    });
    const [grupos, setGrupos] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    const [showSaveModal, setShowSaveModal] = useState(false); // Nuevo estado para el modal
    const [pdfBlob, setPdfBlob] = useState(null);

    useEffect(() => {
        const fetchGrupos = async () => {
            try {
                const response = await fetch(`${URLFireBase}groups`, { method: 'GET' });
                if (!response.ok) {
                    throw new Error('Error al obtener los grupos');
                }
                const data = await response.json();
                setGrupos(data.groups || []);
            } catch (error) {
                console.error("Error al obtener los grupos:", error);
            }
        };
        fetchGrupos();
    }, []);

    useEffect(() => {
        const fetchEstudiantes = async () => {
            if (!form.grupo) return;
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                  throw new Error('Usuario no autenticado.'); 
                }
                
                const token = await currentUser.getIdToken(); // Obtener el token de autenticación
                
                const response = await fetch(`${URLFireBase}users-by-group/${form.grupo}`, {
                  method: 'GET', 
                  headers: {
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json'  
                  },
                });

                if (!response.ok) {
                    throw new Error('Error al obtener los estudiantes');
                }
                const data = await response.json();
                setEstudiantes(data.users || []);
            } catch (error) {
                console.error(`Error al obtener estudiantes del grupo ${form.grupo}:`, error);
            }
        };
        fetchEstudiantes();
    }, [form.grupo]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm({
            ...form,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const renderLatexToImage = async (latex) => {
        // Crear un elemento temporal para renderizar la fórmula
        const tempContainer = document.createElement('div');
        tempContainer.style.position = 'absolute';
        tempContainer.style.left = '-9999px';
        tempContainer.style.top = '-9999px';
        tempContainer.style.display = 'inline-block';
        tempContainer.style.padding = '10px';
        tempContainer.style.backgroundColor = 'white';
        document.body.appendChild(tempContainer);

        try {
            // Renderizar la fórmula LaTeX usando KaTeX
            katex.render(latex, tempContainer, {
                displayMode: true,
                throwOnError: false,
                output: 'html',
                strict: false
            });

            // Convertir el elemento renderizado a canvas
            const canvas = await html2canvas(tempContainer, {
                scale: 3, // Aumentar la calidad
                backgroundColor: null,
                logging: false
            });

            // Convertir el canvas a una imagen base64
            const imageData = canvas.toDataURL('image/png');
            
            // Limpiar
            document.body.removeChild(tempContainer);
            
            return imageData;

        } catch (error) {
            console.error("Error al renderizar fórmula LaTeX:", error);
            document.body.removeChild(tempContainer);
            return null;
        }
    };

    const savePDFToFirebase = async () => {
        // Verificar si el usuario está autenticado
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error("Usuario no autenticado.");
            alert("Debes estar autenticado para guardar el PDF.");
            return;
        }
    
        const token = await currentUser.getIdToken(); // Obtener el token de Firebase
    
        if (pdfBlob.type !== 'application/pdf') {
            alert("El archivo no es un PDF. Por favor, selecciona un archivo válido.");
            console.error("Error: El archivo no es un PDF", pdfBlob.type);
            return;
        }
    
        try {
            const titulo = `examen_${form.grupo}_${new Date().toISOString().split('T')[0]}`; // Generar título dinámico
            
            const formData = new FormData();
            formData.append('userId', userId); // 'userId' como texto
            formData.append('data', pdfBlob, `${titulo}.pdf`); // Agregar el archivo PDF con el título dinámico
            formData.append('title', titulo); // Agregar el título como parte de los datos enviados
    
            // Enviar la solicitud al backend para guardar el PDF en Firebase
            const response = await fetch(`${URLFireBase}save-pdf`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Incluir el token en la cabecera Authorization
                },
                body: formData, // El cuerpo es FormData
            });
    
            const result = await response.json();
    
            if (!response.ok) {
                throw new Error(result.error || 'Error al guardar el PDF en Firebase');
            }
    
            // Mostrar la URL del archivo guardado
            alert(`PDF guardado exitosamente. URL: ${result.fileUrl}`);
            console.log("Archivo guardado en Firebase:", result.fileUrl);
    
        } catch (error) {
            console.error("Error al guardar el PDF en Firebase:", error);
            alert(`Ocurrió un error al guardar el PDF. Detalles: ${error.message}`);
        }
    
        // Ocultar el modal después de guardar
        setShowSaveModal(false);
    };
    
    
    
    const generatePDF = async () => {
        if (!estudiantes || estudiantes.length === 0) {
            alert("No hay estudiantes en este grupo para generar el PDF.");
            return;
        }
    
        // Validación para exámenes diferentes
        if (form.examenesDiferentes > estudiantes.length) {
            alert("El número de exámenes diferentes no puede ser mayor que el número de estudiantes.");
            return;
        }
        const encabezado = (pdf, estudiante) => {
            const logo = new Image();
            logo.src = "/imagens/uteq_logo.png";
            pdf.addImage(logo, "PNG", 10, 10, 45, 20);

            const pageWidth = pdf.internal.pageSize.width;
            pdf.setFont("Helvetica", "bold");
            pdf.setFontSize(13);
            pdf.text("Universidad Tecnológica de Querétaro", pageWidth / 2, 20, { align: "center" });
            pdf.setFontSize(12);
            pdf.text(form.titulo, pageWidth / 2, 30, { align: "center" });
            pdf.text(form.subtitulo, pageWidth / 2, 40, { align: "center" });
        
            pdf.setFontSize(11);
            pdf.text(`Nombre:`, 10, 55);
            pdf.text(estudiante || "Nombre no disponible", 28, 55);
            pdf.text(`Grupo:`, 110, 55);
            pdf.text(form.grupo || "Grupo no definido", 130, 55);
            pdf.text(`Fecha:`, 150, 55);
            pdf.text(form.fecha || new Date().toLocaleDateString(), 170, 55);
            pdf.text("Calificación: _____", 150, 65);
        };
        let preguntas = [];
        try {
            const params = new URLSearchParams({
                difficulty: form.dificultad,
                type: form.tema
            });
            
            const url = `${URLMONGO}formulas?${params.toString()}`;
            console.log("Obteniendo preguntas de:", url);
            
            const response = await fetch(url);
            const data = await response.json();
            
            preguntas = Array.isArray(data) ? data : (data.formulas || []);
            console.log("Preguntas obtenidas:", preguntas);
    
            if (preguntas.length === 0) {
                alert("No se encontraron preguntas para los criterios seleccionados.");
                return;
            }
        } catch (error) {
            console.error("Error al obtener preguntas:", error);
            alert("Error al obtener las preguntas. Por favor, intente nuevamente.");
            return;
        }
    
        // Crear lista de exámenes diferentes
        let examenes = [];
        const examCount = form.examenesDiferentes;
    
        // Crear las versiones del examen
        for (let i = 0; i < examCount; i++) {
            // Si no hay suficientes preguntas, repítelas en un orden diferente
            const shuffledQuestions = [...preguntas];
            for (let j = shuffledQuestions.length - 1; j > 0; j--) {
                const k = Math.floor(Math.random() * (j + 1));
                [shuffledQuestions[j], shuffledQuestions[k]] = [shuffledQuestions[k], shuffledQuestions[j]];
            }
            examenes.push(shuffledQuestions.slice(0, form.numPreguntas));
        }
    
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 20;
    
        for (let estudianteIndex = 0; estudianteIndex < estudiantes.length; estudianteIndex++) {
            const nombre = estudiantes[estudianteIndex];
            
            if (estudianteIndex > 0) {
                pdf.addPage();
            }
    
            encabezado(pdf, nombre);

            pdf.text("Instrucciones:", 10, 72);
            pdf.text(form.instrucciones || "Contesta las preguntas y encierra la respuesta.", 40, 72, { maxWidth: 160 });
        
            // Variables para el control de posición
            let yPosition = margin + 90;
    
            // Asignar examen a cada estudiante
            const examenParaEstudiante = examenes[estudianteIndex % examenes.length];
    
            // Agregar preguntas y fórmulas
            for (let i = 0; i < examenParaEstudiante.length; i++) {
                if (yPosition > pageHeight - margin) {
                    pdf.addPage();
                    yPosition = margin;
                }
    
                // Número de pregunta
                pdf.setFont("Helvetica", "bold");
                pdf.text(`${i + 1}.`, margin, yPosition);
                
                try {
                    // Renderizar fórmula
                    const formulaImage = await renderLatexToImage(examenParaEstudiante[i].latex);
                    if (formulaImage) {
                        // Calcular dimensiones para la imagen
                        const imgWidth = 80;
                        const imgHeight = 25;
                        
                        // Agregar imagen de la fórmula
                        pdf.addImage(
                            formulaImage,
                            'PNG',
                            margin + 10,
                            yPosition - 15,
                            imgWidth,
                            imgHeight
                        );
                        
                        yPosition += 30; // Espacio para la siguiente pregunta
                    } else {
                        pdf.setFont("Helvetica", "normal");
                        pdf.text("[Error al renderizar fórmula]", margin + 10, yPosition);
                        yPosition += 15;
                    }
                } catch (error) {
                    console.error(`Error en pregunta ${i + 1}:`, error);
                    pdf.text("[Error en la fórmula]", margin + 10, yPosition);
                    yPosition += 15;
                }
    
                // Espacio para respuesta
                yPosition += 15;
            }
        }
       
        
        // Guardar PDF
        try {
            pdf.save(`examen_${form.grupo}_${new Date().toISOString().split('T')[0]}.pdf`);
            setPdfBlob(pdf.output('blob')); // Guardamos el PDF como Blob para que pueda ser usado después
            setShowSaveModal(true);
        } catch (error) {
            console.error("Error al guardar el PDF:", error);
            alert("Error al generar el PDF. Por favor, intente nuevamente.");
        }
    };
    

    
    

    return (
        <div className={styles.formContainer}>
            <h2 className={styles.titulo}>Creación de examen</h2>
            <form className={styles.examForm}>
                <div className={styles.formGroup}>
                    <label>Título</label>
                    <input type="text" name="titulo" value={form.titulo} onChange={handleChange} />
                </div>

                <div className={styles.formGroup}>
                    <label>Subtítulo</label>
                    <input type="text" name="subtitulo" value={form.subtitulo} onChange={handleChange} />
                </div>

                <div className={styles.formGroup}>
                    <label>Fecha</label>
                    <input
                        type="date"
                        name="fecha"
                        value={form.fecha}
                        onChange={handleChange}
                        className={styles.styledDate}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Instrucciones</label>
                    <textarea
                        name="instrucciones"
                        value={form.instrucciones}
                        onChange={handleChange}
                        className={styles.fixedTextarea}
                    ></textarea>
                </div>
                <div className={styles.formGroup}>
                    <label>Grupo</label>
                    <select name="grupo" value={form.grupo} onChange={handleChange}>
                        <option value="">Seleccionar grupo</option>
                        {grupos.map(grupo => (
                            <option key={grupo} value={grupo}>{grupo}</option>
                        ))}
                    </select>
                </div>

                <div className={styles.formGroup}>
                    <label>Tema</label>
                    <input type="text" name="tema" value={form.tema} onChange={handleChange} />
                </div>

                <div className={styles.formGroup}>
                    <label>Dificultad</label>
                    <select name="dificultad" value={form.dificultad} onChange={handleChange}>
                        <option value="">Seleccionar dificultad</option>
                        <option value="Fácil">Fácil</option>
                        <option value="Moderado">Moderado</option>
                        <option value="Difícil">Difícil</option>
                    </select>
                </div>
                <div className={styles.formGroup}>
                    <label>Número de Preguntas</label>
                    <input
                        type="number"
                        name="numPreguntas"
                        value={form.numPreguntas}
                        min="1"
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Exámenes Diferentes</label>
                    <input
                        type="number"
                        name="examenesDiferentes"
                        value={form.examenesDiferentes}
                        min="1"
                        onChange={handleChange}
                    />
                </div>


                <div className={styles.buttonGroup}>
                    <button type="button" onClick={generatePDF}>Descargar PDF</button>
                </div>

            </form>
            
        {showSaveModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h3>¿Deseas guardar el PDF en Firebase?</h3>
                        <button onClick={savePDFToFirebase}>Sí, guardar</button>
                        <button onClick={() => setShowSaveModal(false)}>Cancelar</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreacionExam;