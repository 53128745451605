import React, { useState } from 'react';
import { FaUsers } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import styles from './Styles/TabAdmin.module.css';

const Tab= () => {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate(); 

  const handleSelect = (option) => {
    setSelected(option);
   
    if (option === 'usuarios') {
      navigate('/GestionUsuarios'); 
    }
  };

  return (
    <div className={styles.tabAdministrador}>
      <div
        className={`${styles.tabOption} ${selected === 'usuarios' ? styles.selected : ''}`}
        onClick={() => handleSelect('usuarios')}
      >
        <div className={styles.iconWrapper}>
          <div className={styles.iconBackground}>
            <FaUsers className={styles.icon} /> 
          </div>
        </div>
        <div className={styles.label}>Usuarios</div>
      </div>

    </div>
  );
};

export default Tab;
