// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import GestionUsuarios from './pages/Admin/GestionUsuarios';
import Login from './pages/Login';
import Examen from './pages/Profesor/Examen';
import Historial from './pages/Profesor/Historial';
import Calificaciones from './pages/Profesor/Calificaciones';
import Resultados from './pages/Profesor/Resultados';
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/GestionUsuarios" element={<GestionUsuarios/>} />
      <Route path="/CrearExamen" element={<Examen/>} />
      <Route path="/Historial" element={<Historial/>} />
      <Route path="/Calificaciones" element={<Calificaciones/>} />
      <Route path="/Resultados" element={<Resultados/>} />
    </Routes>
  );
};
export default App;
