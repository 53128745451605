import React, { useEffect, useState } from 'react';
import { Alert, Stack } from '@mui/material'; 
import Header from '../../components/Header';
import TabProfesor from '../../components/TabProfedor';
import HistoralItem from '../../components/HistoralItem';
import { auth } from '../../Firebase/firebaseConfig';
import useAuthGuard from '../../config/authGuard';
import URLFireBase from '../../config/apiConfig';
import ConfirmDeleteModal from '../../modales/ConfirmDeleteModal'; 

const Historial = () => {
  const { error, data } = useAuthGuard();
  const [historial, setHistorial] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [historialError, setHistorialError] = useState(''); // Renombrar este error para evitar el conflicto
  const [success, setSuccess] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [docToDelete, setDocToDelete] = useState(null); 

  useEffect(() => {
    const fetchHistorial = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('Usuario no autenticado.'); 
        }

        const token = await currentUser.getIdToken();

        const response = await fetch(`${URLFireBase}user-docs`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        });

        if (!response.ok) {
          throw new Error('Error al obtener documentos.');
        }

        const docsData = await response.json();
        setHistorial(docsData); 
        setLoading(false); 
      } catch (error) {
        setHistorialError(error.message); 
        setLoading(false); 
      }
    };

    fetchHistorial(); 
  }, []); 

  const handleDownload = (fileUrl, titulo) => {
    try {
      if (!fileUrl) {
        throw new Error("La URL del archivo es inválida.");
      }
    
      // Abre el enlace en una nueva pestaña
      const newTab = window.open(fileUrl, '_blank');
      if (!newTab) {
        throw new Error("No se pudo abrir la nueva pestaña.");
      }
      newTab.focus(); // Opcional, para asegurar que la nueva pestaña sea la activa
  
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      handleError("No se pudo descargar el archivo.");
    }
  };
  
  
  

  const handleConfirmDelete = async () => {
    if (!docToDelete) return;

    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("Usuario no autenticado.");
      handleError("Debes estar autenticado para eliminar el documento.");
      return;
    }

    try {
      const token = await currentUser.getIdToken();
  
      const response = await fetch(`${URLFireBase}delete-doc/${docToDelete}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error('Error al eliminar el documento');
      }
  
      setHistorial((prev) => prev.filter((item) => item.id !== docToDelete));
      handleSuccess('Documento eliminado exitosamente.');
      setShowDeleteModal(false); 
      setDocToDelete(null); 
    } catch (error) {
      console.error('Error al eliminar el documento:', error);
      handleError('Ocurrió un error al eliminar el documento.');
      setShowDeleteModal(false); 
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return ''; 
    const date = new Date(timestamp._seconds * 1000); 
    return date.toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const handleError = (message) => {
    setHistorialError(message);
    setTimeout(() => setHistorialError(''), 3000);
  };

  return (
    <div className="profesor-dashboard">
      <Header />
      <div className="main-content">
        <TabProfesor />

        {/* Alertas de éxito o error */}
        {(success || historialError) && (
          <div style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            zIndex: 1000,
            width: '400px'
          }}>
            <Stack spacing={2}>
              <Alert severity={historialError ? "error" : "success"} onClose={() => { setHistorialError(''); setSuccess(''); }}>
                {historialError || success}
              </Alert>
            </Stack>
          </div>
        )}
      {historial.length > 0 ? (
        historial.map((item) => (
          <HistoralItem
            key={item.id}
            titulo={item.title}
            fecha={formatDate(item.date)}
            onDownload={() => handleDownload(item.fileUrl, item.title)}
            onDelete={() => {
              setDocToDelete(item.id);
              setShowDeleteModal(true);
            }}
          />
        ))
      ) : null}

      </div>
      
      {/* Modal de confirmación */}
      <ConfirmDeleteModal
        open={showDeleteModal} 
        onClose={() => setShowDeleteModal(false)} 
        onConfirm={handleConfirmDelete} 
      />
    </div>
  );
};

export default Historial;
