import React, { useState } from 'react';
import Header from '../../components/Header';
import TabProfesor from '../../components/TabProfedor';
import Grupo from '../../components/Grupo';
import ListaAlumnos from '../../components/ListaAlumnos';
import { AddButton } from '../../components/IconsButtons';
import CalificacionesModal from '../../modales/CalificacionesModal'; 
import useAuthGuard from '../../config/authGuard';

const Calificaciones = () => {
  const { error, data } = useAuthGuard();
  const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
  const [showCalificacionesModal, setShowCalificacionesModal] = useState(false); 
  const manejarGrupoSeleccionado = (grupoId) => {
    setGrupoSeleccionado(grupoId);
  };

  const handleOpenModal = () => {
    setShowCalificacionesModal(true); 
  };

  const handleCloseModal = () => {
    setShowCalificacionesModal(false); 
  };

  return (
    <div className="profesor-dashboard">
      <Header />
      <div className="main-content">
        <TabProfesor />
        <div
          className="add"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <AddButton onClick={handleOpenModal} /> 
        </div>

        <Grupo manejarGrupoSeleccionado={manejarGrupoSeleccionado} /> 
        <ListaAlumnos grupoSeleccionado={grupoSeleccionado} /> 

        {showCalificacionesModal && (
          <CalificacionesModal
            grupoSeleccionado={grupoSeleccionado}
            onClose={handleCloseModal} 
          />
        )}
      </div>
    </div>
  );
};

export default Calificaciones;
