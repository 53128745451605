import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material';

const ConfirmDeleteModal = ({ open, onClose, onConfirm, userName }) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ borderRadius: '10px' }}>
      <DialogTitle>¿Estás seguro de que deseas eliminar?</DialogTitle>
    
      <DialogContent sx={{ borderRadius: '20px', padding: '20px' }}>
        {/* El contenido del modal, si lo hubiera */}
      </DialogContent>

      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 2 }}>
          <Button
            sx={{
              borderRadius: '20px',
              padding: '10px 20px',
              border: '2px solid transparent',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              fontWeight: 'bold',
              borderColor: '#b0b0b0',
              color: '#b0b0b0',
              '&:hover': {
                backgroundColor: '#b0b0b0',
                color: '#ffffff',
              },
            }}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            sx={{
              borderRadius: '20px',
              padding: '10px 20px',
              border: '2px solid transparent',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              fontWeight: 'bold',
              borderColor: '#742b90',
              color: '#742b90',
              '&:hover': {
                backgroundColor: '#742b90',
                color: '#ffffff',
              },
            }}
            onClick={onConfirm}
          >
            Confirmar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
