import React, { useState, useEffect } from 'react';
import styles from './Styles/ListaAlumnos.module.css';
import URLFireBase from '../config/apiConfig';
import AlumnoModal from '../modales/AlumnoModal';
import { auth } from '../Firebase/firebaseConfig'; 
import { Alert, Stack } from '@mui/material';

const ListaAlumnos = ({ grupoSeleccionado }) => {
    const [estudiantes, setEstudiantes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedAlumno, setSelectedAlumno] = useState(null);
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (!grupoSeleccionado) return;

        const fetchEstudiantes = async () => {
            setLoading(true);
            setError(null);
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('Usuario no autenticado.'); 
                }
                
                const token = await currentUser.getIdToken(); // Obtener el token de autenticación
                
                const response = await fetch(`${URLFireBase}users-by-group/${grupoSeleccionado}`, {
                    method: 'GET', 
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json'  
                    },
                });
                
                const data = await response.json();
                const estudiantesProcesados = (data.users || []).map(estudiante => {
                    const partes = estudiante.split(' ');
                    const enrollment = partes.pop(); 
                    const name = partes.join(' '); 
                    return { name, enrollment };
                });
                setEstudiantes(estudiantesProcesados);
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEstudiantes();
    }, [grupoSeleccionado]);

    if (!grupoSeleccionado) {
        return <div className={styles.contenedor}><p>Selecciona un grupo para ver los alumnos.</p></div>;
    }

    if (loading) {
        return <div className={styles.contenedor}><p>Cargando estudiantes...</p></div>;
    }

    if (error) {
        return <div className={styles.contenedor}><p>Error: {error}</p></div>;
    }

    const handleCloseModal = () => {
        setSelectedAlumno(null);
    };

    const handleSuccess = (message) => {
        setSuccess(message);
        setTimeout(() => {
            setSuccess('');  // Limpiar el mensaje de éxito
        }, 3000); // Limpiar el mensaje después de 3 segundos
    };
    
    const handleError = (message) => {
        setError(message);
        setTimeout(() => {
            setError(null);  // Limpiar el mensaje de error
        }, 3000); // Limpiar el error después de 3 segundos
    };

    return (
        <div className={styles.contenedor}>
            <h3>Lista de Alumnos</h3>

            {/* Mostrar alertas en la parte superior derecha */}
            {(success || error) && (
                <div style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1000,
                    width: '400px'
                }}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        {success && <Alert severity="success">{success}</Alert>}
                        {error && <Alert severity="error">{error}</Alert>}
                    </Stack>
                </div>
            )}

            <div className={styles.lista}>
                {estudiantes.map((alumno, index) => (
                    <div
                        key={index}
                        className={styles.alumno}
                        onClick={() => setSelectedAlumno(alumno)}
                    >
                        <div className={styles.info}>
                            <h4>{alumno.name}</h4>
                            <p>Matrícula: {alumno.enrollment}</p>
                        </div>
                    </div>
                ))}
            </div>

            {selectedAlumno && (
                <AlumnoModal
                    alumno={selectedAlumno}
                    onClose={handleCloseModal}
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            )}
        </div>
    );
};

export default ListaAlumnos;
