import React, { useState, useEffect } from 'react';
import styles from './Styles/Grupo.module.css';
import URLFireBase from '../config/apiConfig';

const Grupo = ({ manejarGrupoSeleccionado }) => {
    const [grupoSeleccionado, setGrupoSeleccionado] = useState('');
    const [grupos, setGrupos] = useState([]);

    const manejarCambio = (event) => {
        const idGrupo = event.target.value; 
        setGrupoSeleccionado(idGrupo);
        manejarGrupoSeleccionado(idGrupo);
    };

    useEffect(() => {
        const fetchGrupos = async () => {
            try {
                const response = await fetch(`${URLFireBase}groups`, { method: 'GET' });
                if (!response.ok) {
                    throw new Error('Error al obtener los grupos');
                }
                const data = await response.json();
                setGrupos(data.groups || []);
            } catch (error) {
                console.error("Error al obtener los grupos:", error);
            }
        };
        fetchGrupos();
    }, []);


    return (
        <div className={styles.contenedor}>
            <h2>Selecciona un Grupo</h2>
            <div className={styles.selectContainer}>
                <select
                    value={grupoSeleccionado} 
                    onChange={manejarCambio}
                    className={styles.select}
                >
                    <option value="">-- Seleccionar grupo --</option>
                        {grupos.map(grupo => (
                            <option key={grupo} value={grupo}>{grupo}</option>
                        ))}
                </select>
            </div>
        </div>
    );
};

export default Grupo;
