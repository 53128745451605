import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';  
import Grupo from '../components/Grupo';
import URLFireBase from '../config/apiConfig';
import { auth } from '../Firebase/firebaseConfig'; 

const CalificacionesModal = ({ onClose }) => {
    const [examen, setExamen] = useState('');
    const [calificaciones, setCalificaciones] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [showAlert, setShowAlert] = useState(false);  // Estado para mostrar la alerta
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();  
    
    const manejarCalificacion = (enrollment, calificacionNumerica) => {
        const calificacionLetra = obtenerCalificacionLetra(calificacionNumerica);
        setCalificaciones((prevCalificaciones) => {
            const updatedCalificaciones = prevCalificaciones.filter((item) => item.enrollment !== enrollment);
            updatedCalificaciones.push({ enrollment, calificacionNumerica, calificacionLetra });
            return updatedCalificaciones;
        });
    };

    const obtenerCalificacionLetra = (calificacion) => {
        if (calificacion === 10) return 'AU'; 
        if (calificacion === 9) return 'DE'; 
        if (calificacion === 8) return 'SA'; 
        return 'NA'; 
    };

    useEffect(() => {
        if (!grupoSeleccionado) return;

        const fetchEstudiantes = async () => {
            setLoading(true);
            setError(null);
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('Usuario no autenticado.'); 
                }
                
                const token = await currentUser.getIdToken(); 
                
                const response = await fetch(`${URLFireBase}users-by-group/${grupoSeleccionado}`, {
                    method: 'GET', 
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json'  
                    },
                });
                const data = await response.json();
                const estudiantesProcesados = (data.users || []).map(estudiante => {
                    const partes = estudiante.split(' ');
                    const enrollment = partes.pop(); 
                    const name = partes.join(' '); 
                    return { name, enrollment };
                });
                setEstudiantes(estudiantesProcesados);
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEstudiantes();
    }, [grupoSeleccionado]);

    const manejarSubmit = async () => {
        try {
            const calificacionesData = calificaciones.map(calificacion => ({
                examen,
                matricula: calificacion.enrollment,
                calificacionNumerica: calificacion.calificacionNumerica,
            }));
    
            const response = await fetch(`${URLFireBase}/calificaciones`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ calificaciones: calificacionesData }),
            });
    
            if (response.ok) {
                setAlertMessage('Calificaciones guardadas correctamente');
                setShowAlert(true); // Mostrar la alerta
                setTimeout(() => {
                    setShowAlert(false); // Ocultar la alerta después de 2 segundos
                    onClose(); // Cerrar el modal
                    navigate('/Resultados', { state: { calificaciones, examen, grupo: grupoSeleccionado } });
                }, 2000); // Esperar 2 segundos antes de redirigir
            } else {
                throw new Error('Error al guardar las calificaciones');
            }
        } catch (error) {
            setAlertMessage('Hubo un problema al guardar las calificaciones');
            setShowAlert(true); // Mostrar la alerta de error
            setTimeout(() => setShowAlert(false), 2000); // Ocultar la alerta después de 2 segundos
        }
    };

    return (
        <Modal open={true} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,  
                    backgroundColor: 'white',
                    borderRadius: 2,
                    padding: '30px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    alignItems: 'center',
                }}
            >
                <h3>Calificar Examen</h3>

                {showAlert && (
                    <Alert severity="info" sx={{ width: '100%', marginBottom: '15px' }}>
                        {alertMessage}
                    </Alert>
                )}

                <TextField
                    label="Examen"
                    fullWidth
                    variant="outlined"
                    value={examen}
                    onChange={(e) => setExamen(e.target.value)}
                    sx={{ marginBottom: '15px' }}
                />

                <div style={{ width: '100%', marginBottom: '15px' }}>
                    <Grupo manejarGrupoSeleccionado={setGrupoSeleccionado} />
                </div>

                {loading ? (
                    <p>Cargando alumnos...</p>
                ) : (
                    estudiantes.length > 0 && (
                        <div style={{ width: '100%', overflowX: 'auto' }}>
                            <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '8px' }}>
                                <thead>
                                    <tr>
                                        <th style={styles.tableHeader}>Alumno</th>
                                        <th style={styles.tableHeader}>Calificación</th>
                                        <th style={styles.tableHeader}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {estudiantes.map((alumno, index) => (
                                        <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                                            <td style={styles.tableCell}>{alumno.name}</td>
                                            <td style={styles.tableCell}>
                                                <input
                                                    type="number"
                                                    min="0"
                                                    max="10"
                                                    onChange={(e) =>
                                                        manejarCalificacion(alumno.enrollment, parseFloat(e.target.value))
                                                    }
                                                    style={styles.inputField}
                                                />
                                            </td>
                                            <td style={styles.tableCell}>
                                                {calificaciones.find((item) => item.enrollment === alumno.enrollment)
                                                    ?.calificacionLetra || 'NA'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                )}

                {error && (
                    <Alert severity="error" sx={{ width: '100%', marginBottom: '15px' }}>
                        {error}
                    </Alert>
                )}

                <div style={{display: 'flex', justifyContent: 'center', width: '100%', gap: 2  }}>
                    <Button
                        sx={{
                            borderRadius: '20px',
                            padding: '10px 20px',
                            border: '2px solid transparent',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            fontWeight: 'bold',
                            borderColor: '#b0b0b0',
                            color: '#b0b0b0',
                            '&:hover': {
                              backgroundColor: '#b0b0b0',
                              color: '#ffffff',
                            },
                          }}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                       sx={{
                        borderRadius: '20px',
                        padding: '10px 20px',
                        border: '2px solid transparent',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        fontWeight: 'bold',
                        borderColor: '#742b90',
                        color: '#742b90',
                        '&:hover': {
                          backgroundColor: '#742b90',
                          color: '#ffffff',
                        },
                      }}
                        onClick={manejarSubmit}
                    >
                        Confirmar
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

const styles = {
    tableHeader: {
        textAlign: 'center',   
        borderBottom: '2px solid #742b90', 
        backgroundColor: '#742b90',  
        color: 'white',  
        padding: '10px 15px',  
        borderRadius: '8px',  
    },
    tableCell: {
        textAlign: 'center',  
        padding: '10px 15px',
        borderBottom: '1px solid #ddd',
    },
    evenRow: {
        backgroundColor: '#f9f9f9',  
    },
    oddRow: {
        backgroundColor: '#ffffff',  
    },
    inputField: {
        padding: '5px 10px',
        borderRadius: '5px',
        width: '60px',
        textAlign: 'center',
        border: '1px solid #ddd',
    },
};

export default CalificacionesModal;
