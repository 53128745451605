import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AlertProvider } from './components/AlertContext'; 
import { UserProvider } from './pages/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AlertProvider>
      <UserProvider>  
          <App />
        </UserProvider>
      </AlertProvider>
    </BrowserRouter>
  </React.StrictMode>
);
