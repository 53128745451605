import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";  // Importa signOut desde Firebase

const firebaseConfig = {
  apiKey: "AIzaSyD6QE1qmCteCNQYPp6ZoTnZPqs3Unbc234",
  authDomain: "testflow-afb1d.firebaseapp.com",
  projectId: "testflow-afb1d",
  storageBucket: "testflow-afb1d.firebasestorage.app",
  messagingSenderId: "135674319995",
  appId: "1:135674319995:web:7c08abbeff7e57df14fede",
  measurementId: "G-SNNXDHXB9L"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, signOut }; // Exporta auth y signOut
