import React from 'react';
import styles from './Styles/HistoralItem.module.css';
import { DownloadButton, CloseButton } from './IconsButtons';

function HistoralItem({ titulo, fecha, onDownload, onDelete }) {
  return (
    <div className={styles.container}>
      <div className={styles.examenItemContainer}>
        <div className={styles.examenInfo}>
          <span className={styles.examenTitulo}>{titulo}</span>
          <span className={styles.examenFecha}>{fecha}</span>
        </div>
        <div className={styles.iconButtons}>
            <DownloadButton onClick={onDownload}/>
            <CloseButton  onClick={onDelete}/>
            
        </div>
      </div>
    </div>
  );
}

export default HistoralItem;
