import { useUser } from '../pages/UserContext';
import styles from './Styles/Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { signOut } from "firebase/auth";
import { auth } from "../Firebase/firebaseConfig";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const { userName } = useUser();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img className={styles.resultado2Icon} alt="Logo" src="/imagens/logo.png" />
        <div className={styles.testflow}>TESTFLOW</div>
      </div>
      <div className={styles.userSection}>
        <FontAwesomeIcon icon={faUserCircle} className={styles.userIcon} />
        <strong className={styles.faUser}>{userName || "Usuario"}</strong>
        <FontAwesomeIcon
          icon={faLock}
          className={styles.lockIcon}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default Header;
