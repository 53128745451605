import React from 'react';
import Styles from './Styles/SelectUser.module.css'; 
const SelectUser = ({ onFilterChange }) => {
  const handleRoleChange = (e) => {
    onFilterChange(e.target.value);
  };

  return (
    <div className={Styles.filterContainer}>
      <select onChange={handleRoleChange} className={Styles.select}>
        <option value="profesor">Profesores</option>
        <option value="admin">Administradores</option>
        <option value="alumno">Estudiantes</option>

      </select>
    </div>
  );
};

export default SelectUser;
