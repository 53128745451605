import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Alert, CircularProgress, TextField } from "@mui/material";
import URLFireBase from "../config/apiConfig";
import { auth } from '../Firebase/firebaseConfig'; 

const AlumnoModal = ({ alumno, onClose, onSuccess, onError }) => {
  const [calificaciones, setCalificaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!alumno) return;
  
    const fetchCalificaciones = async () => {
      setLoading(true);
      setError(null);
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('Usuario no autenticado');
        }
  
        const token = await currentUser.getIdToken();
  
        const response = await fetch(`${URLFireBase}alumno-calif/${alumno.enrollment}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error("Error al obtener las calificaciones");
        }
  
        const data = await response.json();
        setCalificaciones(data.calificaciones || []);
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCalificaciones();
  }, [alumno]); 

  const getCategoria = (calificacionNumerica) => {
    if (calificacionNumerica === 10) return "AU";
    else if (calificacionNumerica === 9) return "DE";
    else if (calificacionNumerica === 8) return "SA";
    return "NA";
  };

  const handleSaveChanges = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('Usuario no autenticado');
    }
  
    const token = await currentUser.getIdToken();
  
    try {
      for (let calificacion of calificaciones) {
        const response = await fetch(`${URLFireBase}update-calif`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            matricula: alumno.enrollment, 
            examen: calificacion.examen,
            calificacionNumerica: calificacion.calificacionNumerica,
          }),
        });
  
        if (!response.ok) {
          throw new Error("Error al guardar las calificaciones");
        }
      }
  
      onSuccess("Cambios guardados con éxito"); // Llamar la función de éxito
      onClose(); // Cerrar el modal
    } catch (error) {
      setError(error.message);
      onError(error.message); // Llamar la función de error
    }
  };
  
  const handleChangeCalificacion = (index, field, value) => {
    const updatedCalificaciones = [...calificaciones];
    updatedCalificaciones[index][field] = value;
    setCalificaciones(updatedCalificaciones);
  };

  if (!alumno) {
    return null;
  }

  return (
    <Modal open={!!alumno} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: "#ffffff",
          borderRadius: 2,
          padding: "30px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box sx={{ textAlign: "center", padding: "10px", border: "1px solid #ddd", borderRadius: "10px", backgroundColor: "#f9f9f9", marginBottom: "15px" }}>
          <h3 style={{ margin: 0, fontWeight: "bold" }}>{alumno.name}</h3>
          <p style={{ margin: 0, color: "#555" }}>Matrícula: {alumno.enrollment}</p>
        </Box>

        {loading && <CircularProgress />}
        {error && <Alert severity="error" sx={{ width: "100%" }}>{error}</Alert>}

        {!loading && !error && (
          <Box sx={{ width: "100%", border: "1px solid #ddd", borderRadius: "10px", overflow: "hidden", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }}>
              <thead style={{ backgroundColor: "#742b90", color: "#ffffff", fontWeight: "bold" }}>
                <tr>
                  <th style={{ padding: "10px" }}>Examen</th>
                  <th style={{ padding: "10px" }}>Calificación</th>
                  <th style={{ padding: "10px" }}>Categoría</th>
                </tr>
              </thead>
              <tbody>
                {calificaciones.map((calificacion, index) => (
                  <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff" }}>
                    <td style={{ padding: "10px" }}>{calificacion.examen}</td>
                    <td style={{ padding: "10px" }}>
                      <TextField
                        type="number"
                        value={calificacion.calificacionNumerica}
                        onChange={(e) => handleChangeCalificacion(index, 'calificacionNumerica', e.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </td>
                    <td style={{ padding: "10px" }}>{getCategoria(calificacion.calificacionNumerica)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button onClick={onClose}
           sx={{
            borderRadius: '20px',
            padding: '10px 20px',
            border: '2px solid transparent',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            fontWeight: 'bold',
            borderColor: '#b0b0b0',
            color: '#b0b0b0',
            '&:hover': {
              backgroundColor: '#b0b0b0',
              color: '#ffffff',
            },
          }}>Cancelar</Button>
          <Button onClick={handleSaveChanges}
            sx={{
              borderRadius: '20px',
              padding: '10px 20px',
              border: '2px solid transparent',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              fontWeight: 'bold',
              borderColor: '#742b90',
              color: '#742b90',
              '&:hover': {
                backgroundColor: '#742b90',
                color: '#ffffff',
              },
            }}>Confirmar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlumnoModal;
