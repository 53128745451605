import React from 'react';
import Tab from '../../components/Tab';
import UserTable from '../../components/Table';
import Header from '../../components/Header';
import useAuthGuard from '../../config/authGuard';

const GestionUsuarios = () => {
  const { error, data } = useAuthGuard();

  if (error) return <p>{error}</p>;

  return (
    <div className="admin-dashboard">
      <Header />
      <div className="main-content">
        <Tab />
        <UserTable data={data} />
      </div>
    </div>
  );
};

export default GestionUsuarios;
