import React, { useState } from 'react';
import { FaHistory, FaClipboardList, FaEdit, FaFileUpload } from 'react-icons/fa'; // New icons for the new options
import { useNavigate } from 'react-router-dom';
import styles from './Styles/TabAdmin.module.css';

const TabProfesor = () => {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate(); // Hook para navegar a otras rutas

  // Función para manejar la selección
  const handleSelect = (option) => {
    setSelected(option);
    switch (option) {
      case 'historial':
        navigate('/Historial'); // Ruta de navegación a Historial
        break;
      case 'calificaciones':
        navigate('/Calificaciones'); // Ruta de navegación a Calificaciones
        break;
      case 'crearExamen':
        navigate('/CrearExamen'); // Ruta de navegación a Crear Examen
        break;
      
      default:
        break;
    }
  };

  return (
    <div className={styles.tabAdministrador}>
      {/* Opción 1: Historial */}
      <div
        className={`${styles.tabOption} ${selected === 'historial' ? styles.selected : ''}`}
        onClick={() => handleSelect('historial')}
      >
        <div className={styles.iconWrapper}>
          <div className={styles.iconBackground}>
            <FaHistory className={styles.icon} /> {/* Ícono de historial */}
          </div>
        </div>
        <div className={styles.label}>Historial</div>
      </div>

      {/* Opción 2: Calificaciones */}
      <div
        className={`${styles.tabOption} ${selected === 'calificaciones' ? styles.selected : ''}`}
        onClick={() => handleSelect('calificaciones')}
      >
        <div className={styles.iconWrapper}>
          <div className={styles.iconBackground}>
            <FaClipboardList className={styles.icon} /> {/* Ícono de calificaciones */}
          </div>
        </div>
        <div className={styles.label}>Calificaciones</div>
      </div>

      {/* Opción 3: Crear Examen */}
      <div
        className={`${styles.tabOption} ${selected === 'crearExamen' ? styles.selected : ''}`}
        onClick={() => handleSelect('crearExamen')}
      >
        <div className={styles.iconWrapper}>
          <div className={styles.iconBackground}>
            <FaEdit className={styles.icon} /> {/* Ícono de crear examen */}
          </div>
        </div>
        <div className={styles.label}>Crear Examen</div>
      </div>

      {/* Opción 4: Subir Documento */}
      
    </div>
  );
};

export default TabProfesor;
